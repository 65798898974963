<template lang="pug">
  v-autocomplete(
    tabindex="0"
    prepend-inner-icon="mdi-magnify"
    no-resize
    filled
    label="Region"
    :loading="areRegionsPending"
    :items="regions"
    @input="pickRegion"
    @blur="$emit('blur',$event)"
    :value="defaultValue || value"
    :return-object="false"
    :cache-items="false"
    item-text="name"
    item-value="id"
    :rules="rules ? rules : null"
    :search-input.sync="search"
    :hint="hint"
    persistent-hint
  )
</template>

<script>
import { computed, ref, watch, onMounted } from '@vue/composition-api'
import { useFind, useGet } from 'feathers-vuex'

export default {
  name: 'RegionsAutocomplete',
  props: {
    rules: {
      type: Array,
      required: false,
      default: () => []
    },
    value: {
      type: String
    },
    query: {
      type: Object,
      required: false,
      default: () => {}
    },
    hint: {
      type: String,
      required: false
    }
  },
  setup (props, context) {
    const search = ref('')
    const { Region } = context.root.$FeathersVuex.api
    const defaultValue = ref(props.value)

    // Force refresh region data on component mount
    onMounted(async () => {
      if (props.value) {
        // Force clear cache and refetch
        await context.root.$store.dispatch('regions/get', props.value, { force: true })

        const { item: selectedRegion } = useGet({
          model: Region,
          id: props.value
        })

        watch(selectedRegion, (region) => {
          if (region) {
            defaultValue.value = region.id
            // Force update items list to include selected region
            useFind({
              model: Region,
              params: computed(() => ({
                query: {
                  id: region.id
                }
              }))
            })
          }
        }, { immediate: true })
      }
    })

    const query = {
      ...props.query,
      $limit: 5
    }
    const params = computed(() => {
      const paramsQuery = { ...query }

      if (search.value) {
        paramsQuery.name = {
          $regex: search.value,
          $options: 'gi'
        }
      }

      return { query: paramsQuery }
    })

    const fetchParams = computed(() => {
      const paramsQuery = { ...query }

      if (search.value) {
        paramsQuery.name = {
          $iRegexp: search.value
        }
      }

      return { query: paramsQuery }
    })

    const { items: regions, isPending: areRegionsPending } = useFind({
      model: Region,
      params,
      fetchParams
    })
    console.log('defaultValue', defaultValue)
    return {
      search,
      regions,
      areRegionsPending,
      defaultValue
    }
  },
  methods: {
    pickRegion: function (regionId) {
      this.$emit('input', regionId)
    }
  }
}
</script>
